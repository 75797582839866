import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderLogo from "../custom/Logo";
// import img_header_top from "../assets/images/rolav-header-1.png";
import img_header_top from "../assets/images/rolav-top-3.png";

const Header = () => {
    const [currentPage, setCurrentPage] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const currentPath = window.location.pathname; // Obține URL-ul curent (ex: "/acasa", "/despre-noi", etc.)
        const currentPageName = currentPath.substring(1); // Elimină primul caracter "/", astfel încât să obținem numele paginii (ex: "acasa", "despre-noi", etc.)
        setCurrentPage(currentPageName);
    }, [location]);

    return (
        <nav className="fixed top-0 left-0 z-20 w-full border-gray-200 mb-5">
            <div className="bg-white py-1 px-6 md:px-0">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-center mx-auto">
                    <figure>
                        <img
                            src={img_header_top}
                            alt="art cover"
                            loading="lazy"
                            className="h-[3rem] md:h-[7rem] w-full"
                        />
                    </figure>
                </div>
            </div>

            <div className="bg-green-600">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 ">
                    <a href="" className="flex items-center">
                        <HeaderLogo className="h-8 mr-3" alt="Rolav logo" width={"10rem"} />
                    </a>
                    <button
                        data-collapse-toggle="navbar-default"
                        type="button"
                        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
                        aria-controls="navbar-default"
                        aria-expanded="false"
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 17 14"
                        >
                            <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M1 1h15M1 7h15M1 13h15"
                            />
                        </svg>
                    </button>
                    <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                        <ul className="font-semibold text-xl  flex flex-col p-4 md:p-0 mt-4 border border-gray-100 bg-green-600 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-green-600 ">
                    
                            <li>
                                <button
                                    onClick={() => {
                                        navigate("despre-noi");
                                    }}
                                    id="despre_noi"
                                    className={`block py-2 pl-3 pr-4 ${
                                        currentPage === "despre-noi" ? "text-orange-400" : "text-white "
                                    } rounded ease-in duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-orange-400 md:p-0 `}
                                >
                                    Despre noi
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => {
                                        navigate("informatii");
                                    }}
                                    id="informatii"
                                    className={`block py-2 pl-3 pr-4 ${
                                        currentPage === "informatii" ? "text-orange-400" : "text-white "
                                    } rounded ease-in duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-orange-400 md:p-0 `}
                                >
                                    Informatii
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => {
                                        navigate("produse");
                                    }}
                                    id="produse"
                                    className={`block py-2 pl-3 pr-4 ${
                                        currentPage === "produse" ? "text-orange-400" : "text-white "
                                    } rounded ease-in duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-orange-400 md:p-0 `}
                                >
                                    Produse
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => {
                                        navigate("recicleaza-cu-noi");
                                    }}
                                    id="recicleaza"
                                    className={`block py-2 pl-3 pr-4 ${
                                        currentPage === "recicleaza-cu-noi" ? "text-orange-400" : "text-white "
                                    } rounded ease-in duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-orange-400 md:p-0 `}
                                >
                                    Recicleaza cu noi
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => {
                                        navigate("proiecte-europene");
                                    }}
                                    id="recicleaza"
                                    className={`block py-2 pl-3 pr-4 ${
                                        currentPage === "proiecte-europene" ? "text-orange-400" : "text-white "
                                    } rounded ease-in duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-orange-400 md:p-0 `}
                                >
                                   Proiecte Europene
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => {
                                        navigate("anunturi");
                                    }}
                                    id="anunturi"
                                    className={`block py-2 pl-3 pr-4 ${
                                        currentPage === "anunturi" ? "text-orange-400" : "text-white "
                                    } rounded ease-in duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-orange-400 md:p-0 `}
                                >
                                    Anunturi
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => {
                                        navigate("contact");
                                    }}
                                    id="contact"
                                    className={`block py-2 pl-3 pr-4 ${
                                        currentPage === "contact" ? "text-orange-400" : "text-white "
                                    } rounded ease-in duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-orange-400 md:p-0 `}
                                >
                                    Contact
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;

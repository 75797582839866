import React from "react";
import img_header_top from "../../assets/images/rolav-top-3.png";
import img2 from "../../assets/images/footerAnunt.png";
import img3 from "../../assets/images/Anunt1.jpg";

const Proiecte_europene = () => {
    return (
        <>
            {" "}
            <main className="pt-8 lg:pt-16 bg-white mt-6 -mb-24">
                <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
                    <article className="mx-auto w-full max-w-3xl format format-sm sm:format-base lg:format-lg format-blue ">
                        <figure>
                            <img
                                src={img_header_top}
                                alt="art cover"
                                loading="lazy"
                                width="1000"
                                height="667"
                                className="h-[3.5rem] md:h-[6rem] w-full"
                            />
                            {/* <figcaption>Digital art by Anonymous</figcaption> */}
                        </figure>
                        {/* <header className="mb-4 lg:mb-6 not-format">
                            <div className="flex flex-col  justify-center items-center">
                                <h1 className="text-3xl font-extrabold leading-tight text-blue-900">
                                   
                                </h1>
                                <h1 className="text-3xl font-extrabold leading-tight text-blue-900">
                                    Rolav Consulting SRL
                                </h1>
                            </div>
                        </header> */}
                        <p className="lead mb-2 mt-24">
                           
                            Titlul proiectului: „Achiziție echipamente producție ambalaje de plastic ” <br/>
                            Numele beneficiarului: ROLAV CONSULTING SRL <br/>
                            Obiectivele proiectului: <br/>
                            Obiectivul general al proiectului este: Cresterea competitivitatii economice a Rolav Consulting S.R.L. prin respectarea principiilor de dezvoltare durabila <br/>
                            Obiectivul specific al proiectului este: Intrarea pe piata producatorilor de ambalaje din plastic si consolidarea pozitiei pe aceasta piata. <br/>
                            Valoarea totala a proiectului: 3.634.851,43 lei <br/>
                            Valoarea contributiei nerambursabile: 2.582.765,65 lei din care contributia FEDR 2.195.350,80 lei. <br/>
                            Data de incepere a proiectului: 01/01/2020<br/>
                            Data finalizarii proiectului: 31/12/2023 <br/>
                            Rezultatele proiectului:
                            1.Crearea unei noi capacitati de productie a ambalajelor din plastic. <br/>
                            2.Protectia mediului prin utilizarea surselor regenerabile de energie pentru producerea de energie electrica si termica; <br/>
                            Impactul proiectului la nivel local: <br/>
                            - Utilizarea forței de muncă locală; <br/>
                            - Promovarea principiilor privind egalitatea de gen, nediscriminarea, promovarea drepturilor persoanelor din categorii defavorizate, utilizarea eficientă a resurselor; <br/>
                            - Dezvoltarea mediului de afaceri local și regional. <br/>
                            Cod SMIS proiect: 138785 <br/>
                            Contract de finantare nr. 7473/24.12.2021 <br/>
                            Finantat prin Programul Operational Regional 2014-2020, Axa Prioritara 2, “Imbunatatirea competitivitatii intreprinderilor mici si mijlocii”,  Prioritatea de investiții 2.2 – Sprijinirea creării și extinderea capacităților avansate de producție și dezvoltarea serviciilor <br/>
                            Autoritatea de Management POR: Ministerul Investitiilor si Proiectelor Europene. <br/>
                            Organism Intermediar: Agentia pentru Dezvoltare Regionala Sud-Muntenia <br/>
                            Proiectul sustine principiile de nediscriminare, reducerea excluziunii sociale, egalitatea de sanse si de gen precum si accesul la calificare si perfectionare profesionala.<br/>

                        </p>
                        <br />
                        {/* <div className="border border-black p-2 mt-4">
                            <p className="text-center">
                                <b className="text-center">
                                    Rolav Consulting SRL
                                    <br /> Persoana de contact: Laurentiu Vasile Gidea, administrator
                                    <br /> Tel: 0731 146536 / Email: lavalenti2008@yahoo.com
                                </b>
                            </p>
                        </div> */}
                    </article>
                </div>
            </main>
   
        </>
    );
};

export default Proiecte_europene;

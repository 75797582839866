import React from "react";
import ErrorImage from "../../custom/ErrorImage";
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <>
      <section className="relative z-10 bg-primary py-[120px]">
        <ErrorImage />
        <div className="container">
          <div className="flex -mx-4">
            <div className="w-full px-4">
              <div className="mx-auto max-w-[400px] text-center">
               
                <h4 className="mb-3 text-[22px] font-semibold leading-tight text-black mt-8">
                  Oops! Aceasta pagina nu exista
                </h4>
                <p className="mb-8 text-lg text-black">
                  Pagina pe care o cauti sar putea sa fi fost stearsa
                </p>
                <Link
                  to="/"
                  className="inline-block px-8 py-3 text-base bg-green-500 font-semibold text-center text-white transition border border-white rounded-lg hover:bg-green-600 hover:text-primary"
                >
                  Inapoi pe Acasa
                </Link>
              </div>
            </div>
          </div>
        </div>
      
      </section>
    </>
  );
};

export default ErrorPage;

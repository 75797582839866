import React from "react";
import PrimulAnunt from "./anunturi/PrimulAnunt";
import Martie2022 from "./anunturi/Martie2022";
import Aprilie2022 from "./anunturi/Aprilie2022";
import Mai2022 from "./anunturi/Mai2022";
import Iunie2022 from "./anunturi/Iunie2022";
import August2022 from "./anunturi/August2022";
import Septembrie2022 from "./anunturi/Septembrie2022";
import Octombrie2022 from "./anunturi/Octombrie2022";
import Noiembrie2022 from "./anunturi/Noiembrie2022";
import Decembrie2022 from "./anunturi/Decembrie2022";
import Ianuarie2023 from "./anunturi/Ianuarie2023";
import Februarie2023 from "./anunturi/Februarie2023";
import Martie2023 from "./anunturi/Martie2023";
import Aprilie2023 from "./anunturi/Aprilie2023";
import Mai2023 from "./anunturi/Mai2023";
import Iunie2023 from "./anunturi/Iunie2023";
import Iulie2023 from "./anunturi/Iulie2023";
import August2023 from "./anunturi/August2023";
import Septembrie2023 from "./anunturi/Septembrie2023";
import Octombrie2023 from "./anunturi/Octombrie2023";
import Noiembrie2023 from "./anunturi/Noiembrie2023";
import FinalizareProiectRolav from "./anunturi/FinalizareProiectRolav";

import { useNavigate, useParams } from "react-router-dom";

const AnuntHandle = () => {
  const params = useParams();

  const componente = [
    <PrimulAnunt />,
    <Martie2022 />,
    <Aprilie2022 />,
    <Mai2022 />,
    <Iunie2022 />,
    <August2022 />,
    <Septembrie2022 />,
    <Octombrie2022 />,
    <Noiembrie2022 />,
    <Decembrie2022 />,
    <Ianuarie2023 />,
    <Februarie2023 />,
    <Martie2023 />,
    <Aprilie2023 />,
    <Mai2023 />,
    <Iunie2023 />,
    <Iulie2023 />,
    <August2023 />,
    <Septembrie2023 />,
    <Octombrie2023 />,
    <Noiembrie2023 />,
    <FinalizareProiectRolav />,
  ];

  const id = Number(params.id);
  console.log(id, typeof id);

  return componente[id - 1];
};

export default AnuntHandle;

import React from "react";
import img1 from "../../../assets/images/headerAnunt.png";
import img2 from "../../../assets/images/footerAnunt.png";
import img3 from "../../../assets/images/Anunt1.jpg";
const Septembrie2022 = () => {
  return (
    <>
      {" "}
      <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white ">
        <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
          <article className="mx-auto w-full max-w-3xl format format-sm sm:format-base lg:format-lg format-blue ">
            <figure>
              <img
                src={img1}
                alt="art cover"
                loading="lazy"
                width="1000"
                height="667"
                className="h-[6rem] w-full object-fit transition duration-500 group-hover:scale-105 mt-5 mb-5"
              />
              {/* <figcaption>Digital art by Anonymous</figcaption> */}
            </figure>
            <header className="mb-4 lg:mb-6 not-format">
              <h1 className="mb-4 text-lg font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-xl ">
                COMUNICAT DE PRESA – 2023
              </h1>
              <div className="flex flex-col  justify-center items-center">
                <h1 className="text-3xl font-extrabold leading-tight text-blue-900">
                  Anunt lunar implementare proiect POR 2.2
                </h1>
                <h1 className="text-3xl font-extrabold leading-tight text-blue-900">
                  Rolav Consulting SRL
                </h1>
              </div>
            </header>
            <p className="lead mb-2">
              <p>
                Rolav Consulting SRL, in calitate de beneficiar al proiectului,
                va invita sa participati la procedura competitiva pentru
                atribuirea contractului de furnizare echipamente productie
                ambalaje. Pentru obtinerea documentatiei de atribuire a
                ofertelor ne puteti contacta la nr 0731.146.536, sau email
                lavalenti2008@yahoo.com
              </p>
              <br></br>
              Titlul proiectului: „Achiziție echipamente producție ambalaje de
              plastic ” <br />
              Numele beneficiarului: ROLAV CONSULTING SRL
              <br />
              <br />
              Obiectivul general al proiectului este: Cresterea competitivitatii
              economice a Rolav Consulting S.R.L. prin respectarea principiilor
              de dezvoltare durabila
              <br />
              Obiectivul specific al proiectului este: Intrarea pe piata
              producatorilor de ambalaje din plastic si consolidarea pozitiei pe
              aceasta piata.
              <br />
              <br />
              Valoarea totala a proiectului: 3.634.851,43 lei <br />
              Valoarea contributiei nerambursabile: 2.582.765,65 lei din care
              contributia FEDR 2.195.350,80 lei. <br />
              <br />
              Data de incepere a proiectului: 01/01/2020
              <br />
              Data finalizarii proiectului: 31/12/2023
              <br />
              <br />
              Stadiu proiect: in luna septembrie a anului 2022 proiectul este in
              curs de implementare
              <br />
              Cod SMIS proiect: 138785
              <br />
            </p>

            <p>
              Finantat prin Programul Operational Regional 2014-2020, Axa
              Prioritara 2, “Imbunatatirea competitivitatii intreprinderilor
              mici si mijlocii”, Prioritatea de investiții 2.2 – Sprijinirea
              creării și extinderea capacităților avansate de producție și
              dezvoltarea serviciilor Autoritatea de Management POR: Ministerul
              Investitiilor si Proiectelor Europene
            </p>
            <br />
            <p>
              Organism Intermediar: Agentia pentru Dezvoltare Regionala
              Sud-Muntenia Proiectul sustine principiile de nediscriminare,
              reducerea excluziunii sociale, egalitatea de sanse si de gen
              precum si accesul la calificare si perfectionare profesionala.
            </p>
            <br />
            <div className="border border-black p-2 mt-4">
              <p className="text-center">
                <b className="text-center">
                  Rolav Consulting SRL
                  <br /> Persoana de contact: Laurentiu Vasile Gidea,
                  administrator
                  <br /> Tel: 0731 146536 / Email: lavalenti2008@yahoo.com
                </b>
              </p>
            </div>
            <figure>
              <img
                src={img2}
                alt="art cover"
                loading="lazy"
                width="1000"
                height="667"
                className="h-[9rem] w-full object-fit transition duration-500 group-hover:scale-105 mt-5 mb-5"
              />
              {/* <figcaption>Digital art by Anonymous</figcaption> */}
            </figure>
          </article>
        </div>
      </main>
      <aside
        aria-label="Related articles"
        className="py-8 lg:py-24 bg-gray-50 "
      >
        <div className="px-4 mx-auto max-w-screen-xl">
          <h2 className="mb-8 text-2xl font-bold text-gray-900 ">
            Alte anunțuri
          </h2>
          <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
            <article className="max-w-xs">
              <a href="/anunturi/1">
                <img
                  src={img3}
                  alt="Image 1"
                  width="1000"
                  height="667"
                  className="h-[12rem] w-full object-cover transition duration-500 group-hover:scale-105 mt-5 mb-5 rounded-lg "
                />
              </a>
              <h2 className="mb-2 text-xl font-bold leading-tight text-gray-900 ">
                <a href="#">ANUNT DE PARTICIPARE LA PROCEDURA COMPETITIVA</a>
              </h2>
              <p className="mb-4 font-light text-gray-500 ">
                Rolav Consulting SRL, in calitate de beneficiar al proiectului,
                va invita sa participati la procedura competitiva pentru..
              </p>
              <a
                href="#"
                className="inline-flex items-center font-medium underline underline-offset-4 text-green-500  hover:no-underline"
              >
                Citește în 2 minute
              </a>
            </article>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Septembrie2022;

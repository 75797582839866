
const CustomImage = ({ img }) => {
  return (
    <div className=" ml-2 flex p-2 w-50 item-center">
      <img
        className="w-48 h-48  border-2 border-green-600"
        src={img}
        alt="Rolav"
      />
    </div>
  );
};

export default CustomImage;
import React from "react";

const LegislatiePage = () => {
  return (
    <section>
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold  text-black">
          legislatie
        </h2>
        <div className="grid pt-8 text-left border-t border-green-600 md:gap-16  md:grid-cols-2">
          <div >
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
              laudantium officiis similique sint quos quisquam consequatur vel,
              dolor necessitatibus modi saepe repellendus vero debitis nihil,
              molestias delectus voluptatibus magnam! Reprehenderit. Lorem ipsum
              dolor sit amet consectetur adipisicing elit. Quaerat veritatis
              voluptates ea odio vitae perferendis, quas iure sit eveniet
              distinctio tempora, accusamus necessitatibus! Saepe numquam
              tenetur, perspiciatis ea non exercitationem.Lorem ipsum dolor sit
              amet consectetur adipisicing elit. Quasi laudantium officiis
              similique sint quos quisquam consequatur vel, dolor necessitatibus
              modi saepe repellendus vero debitis nihil, molestias delectus
              voluptatibus magnam! Reprehenderit. Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Quaerat veritatis voluptates ea odio
              vitae perferendis, quas iure sit eveniet distinctio tempora,
              accusamus necessitatibus! Saepe numquam tenetur, perspiciatis ea
              non exercitationem.Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Quasi laudantium officiis similique sint quos
              quisquam consequatur vel, dolor necessitatibus modi saepe
              repellendus vero debitis nihil, molestias delectus voluptatibus
              magnam! Reprehenderit. Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Quaerat veritatis voluptates ea odio vitae
              perferendis, quas iure sit eveniet distinctio tempora, accusamus
              necessitatibus! Saepe numquam tenetur, perspiciatis ea non
              exercitationem.Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Quasi laudantium officiis similique sint quos quisquam
              consequatur vel, dolor necessitatibus modi saepe repellendus vero
              debitis nihil, molestias delectus voluptatibus magnam!
              Reprehenderit. Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Quaerat veritatis voluptates ea odio vitae perferendis, quas
              iure sit eveniet distinctio tempora, accusamus necessitatibus!
              Saepe numquam tenetur, perspiciatis ea non exercitationem.Lorem
              ipsum dolor sit amet consectetur adipisicing elit. Quasi
              laudantium officiis similique sint quos quisquam consequatur vel,
              dolor necessitatibus modi saepe repellendus vero debitis nihil,
              molestias delectus voluptatibus magnam! Reprehenderit. Lorem ipsum
              dolor sit amet consectetur adipisicing elit. Quaerat veritatis
              voluptates ea odio vitae perferendis, quas iure sit eveniet
              distinctio tempora, accusamus necessitatibus! Saepe numquam
              tenetur, perspiciatis ea non exercitationem.Lorem ipsum dolor sit
              amet consectetur adipisicing elit. Quasi laudantium officiis
              similique sint quos quisquam consequatur vel, dolor necessitatibus
              modi saepe repellendus vero debitis nihil, molestias delectus
              voluptatibus magnam! Reprehenderit. Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Quaerat veritatis voluptates ea odio
              vitae perferendis, quas iure sit eveniet distinctio tempora,
              accusamus necessitatibus! Saepe numquam tenetur, perspiciatis ea
              non exercitationem.
            </p>
          </div>

          <div>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
              laudantium officiis similique sint quos quisquam consequatur vel,
              dolor necessitatibus modi saepe repellendus vero debitis nihil,
              molestias delectus voluptatibus magnam! Reprehenderit. Lorem ipsum
              dolor sit amet consectetur adipisicing elit. Quaerat veritatis
              voluptates ea odio vitae perferendis, quas iure sit eveniet
              distinctio tempora, accusamus necessitatibus! Saepe numquam
              tenetur, perspiciatis ea non exercitationem.Lorem ipsum dolor sit
              amet consectetur adipisicing elit. Quasi laudantium officiis
              similique sint quos quisquam consequatur vel, dolor necessitatibus
              modi saepe repellendus vero debitis nihil, molestias delectus
              voluptatibus magnam! Reprehenderit. Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Quaerat veritatis voluptates ea odio
              vitae perferendis, quas iure sit eveniet distinctio tempora,
              accusamus necessitatibus! Saepe numquam tenetur, perspiciatis ea
              non exercitationem.Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Quasi laudantium officiis similique sint quos
              quisquam consequatur vel, dolor necessitatibus modi saepe
              repellendus vero debitis nihil, molestias delectus voluptatibus
              magnam! Reprehenderit. Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Quaerat veritatis voluptates ea odio vitae
              perferendis, quas iure sit eveniet distinctio tempora, accusamus
              necessitatibus! Saepe numquam tenetur, perspiciatis ea non
              exercitationem.Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Quasi laudantium officiis similique sint quos quisquam
              consequatur vel, dolor necessitatibus modi saepe repellendus vero
              debitis nihil, molestias delectus voluptatibus magnam!
              Reprehenderit. Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Quaerat veritatis voluptates ea odio vitae perferendis, quas
              iure sit eveniet distinctio tempora, accusamus necessitatibus!
              Saepe numquam tenetur, perspiciatis ea non exercitationem.Lorem
              ipsum dolor sit amet consectetur adipisicing elit. Quasi
              laudantium officiis similique sint quos quisquam consequatur vel,
              dolor necessitatibus modi saepe repellendus vero debitis nihil,
              molestias delectus voluptatibus magnam! Reprehenderit. Lorem ipsum
              dolor sit amet consectetur adipisicing elit. Quaerat veritatis
              voluptates ea odio vitae perferendis, quas iure sit eveniet
              distinctio tempora, accusamus necessitatibus! Saepe numquam
              tenetur, perspiciatis ea non exercitationem.Lorem ipsum dolor sit
              amet consectetur adipisicing elit. Quasi laudantium officiis
              similique sint quos quisquam consequatur vel, dolor necessitatibus
              modi saepe repellendus vero debitis nihil, molestias delectus
              voluptatibus magnam! Reprehenderit. Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Quaerat veritatis voluptates ea odio
              vitae perferendis, quas iure sit eveniet distinctio tempora,
              accusamus necessitatibus! Saepe numquam tenetur, perspiciatis ea
              non exercitationem.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LegislatiePage;

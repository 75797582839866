import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import MobileMenu from "./MobileMenu";

const Layout = ({ children }) => {
    const [isMobileMenu, SetIsmobileMenu] = useState(false);

    return (
        <div>
            <Header />

            <div className="min-h-screen flex flex-col items-center	py-32 mt-24">{children}</div>

            <Footer />
        </div>
    );
};


export default Layout;

import React from "react";
import BigImage from "../../custom/BigImage";
import ImageMasina from "../../assets/images/masinarii.jpeg";
import ImageMasina2 from "../../assets/images/masinarii2.jpeg";
import Spacers from "./../../custom/Spacers";

const RecicleazPage = () => {
    return (
        <>
            <div className="  text-center md:px-12 lg:py-24 lg:text-left max-w-screen-xl">
                <div className="w-100 mx-auto text-neutral-800 sm:max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
                    <div className="grid items-center gap-12 lg:grid-cols-2">
                        <div className="mt-12 lg:mt-0 z-10">
                            <h1 className="mt-0 mb-12 text-4xl font-bold tracking-tight md:text-6xl xl:text-5xl text-[#8fd1897d]">
                                Împreună pentru un <br />
                                <span className="text-green-600">mediu mai curat</span>
                            </h1>
                            <p className="opacity-70 text-green-600">
                                Bun venit pe pagina noastră dedicată reciclării! Suntem o companie pasionată de
                                protejarea mediului înconjurător și ne dorim să construim împreună o lume mai curată și
                                mai sustenabilă. Eforturile noastre se concentrează pe promovarea reciclării ca o
                                soluție esențială pentru combaterea poluării și conservarea resurselor naturale. Vă
                                invităm să ne alăturați mișcării noastre și să deveniți parte a unei schimbări pozitive
                                pentru mediul în care trăim.
                            </p>
                            <Spacers />
                            <BigImage img={ImageMasina} />
                        </div>

                        <div className="relative mb-12 lg:mb-0">
                            <div id="radius-shape-1" className="absolute rounded-full shadow-lg"></div>

                            <div id="radius-shape-2" className="absolute shadow-lg"></div>
                            <div
                                id="formRecicleazaCuNoi"
                                className="relative bg-[hsla(0,0%,100%,0.9)] backdrop-blur-[25px] backdrop-saturate-[200%] block rounded-lg px-6 py-12  md:px-12"
                            >
                                <section className="bg-white ">
                                    <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
                                        <h2 className="mb-4 text-xl font-bold text-black ">Formular de afiliere</h2>
                                        <form action="#">
                                            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                                                <div className="sm:col-span-2">
                                                    <label
                                                        for="name"
                                                        className="block mb-2 text-sm font-medium text-black "
                                                    >
                                                        Nume
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                                                        placeholder="Numele tău aici.."
                                                        required
                                                    />
                                                </div>
                                                <div className="w-full">
                                                    <label
                                                        for="company"
                                                        className="block mb-2 text-sm font-medium text-black "
                                                    >
                                                        Compania
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="company"
                                                        id="company"
                                                        className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                                                        placeholder="Compania pe care o reprezinți.."
                                                    />
                                                </div>
                                                <div className="w-full">
                                                    <label
                                                        for="phone"
                                                        className="block mb-2 text-sm font-medium text-black "
                                                    >
                                                        Număr de telefon
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="phone"
                                                        id="phone"
                                                        className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                                                        placeholder="Numărul tău de telefon.."
                                                        required=""
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        for="reason"
                                                        className="block mb-2 text-sm font-medium text-black "
                                                    >
                                                        Scop
                                                    </label>
                                                    <select
                                                        id="reason"
                                                        className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                                                    >
                                                        <option selected>Vreau o ofertă</option>
                                                        <option>Vreau să vând</option>
                                                        <option>Vreau să cumpăr</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label
                                                        for="email"
                                                        className="block mb-2 text-sm font-medium text-black "
                                                    >
                                                        E-mail
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        id="email"
                                                        className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                                                        placeholder="Adresa ta de e-mail"
                                                        required=""
                                                    />
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <label
                                                        for="description"
                                                        className="block mb-2 text-sm font-medium text-black "
                                                    >
                                                        Mesaj
                                                    </label>
                                                    <textarea
                                                        id="description"
                                                        rows="8"
                                                        className="block p-2.5 w-full text-sm text-black bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
                                                        placeholder="Adaugă un mesaj"
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <button
                                                type="submit"
                                                className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white rounded-lg focus:ring-4 dark:focus:ring-primary-900 bg-green-500 hover:bg-green-600"
                                            >
                                                Trimite cererea
                                            </button>
                                        </form>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RecicleazPage;

import React from 'react'
import Form from './components/Form'

const ContactPage = () => {
  return (
    <div className='flex flex-col w-full items-center'>
        <Form />
    </div>
  )
}

export default ContactPage
import React from "react";
import ErrorImg from "../assets/404.jpg";

const ErrorImage = ({ width }) => {
  return (
    <div>
      <div className=" ml-2 flex w-96 rounded-md p-2 item-center">
        <img
          style={{
            width: width,
          }}
          src={ErrorImg}
          alt="404"
        />
      </div>
    </div>
  );
};

export default ErrorImage;


const BigImage = ({ img ,width="w-full" }) => {
    return (
      <div className=" ml-2 flex p-2 w-50 item-center">
        <img
          className={`${width} `}
          src={img}
          alt="Rolav"
        />
      </div>
    );
  };
  
  export default BigImage;
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomImage from "../../custom/Image";
// images
import Image1 from "../../assets/images/bidon1.cc4a20889f2070e86d59-removebg-preview.png";
import Image2 from "../../assets/images/bidon_2.21fac3e5e9947b917cbe-removebg-preview.png";
import Image3 from "../../assets/images/saci cu snur 2.png";
import Image4 from "../../assets/images/saci cu snur.png";
import Image5 from "../../assets/images/saci simpli.png";
import Image6 from "../../assets/images/tshirt_bag.png";
import Image7 from "../../assets/images/masinarii2.jpeg";
import Spacers from "../../custom/Spacers";

const ProdusePage = () => {
    const navigate = useNavigate();
    return (
        <>
            <section className="flex flex-col  items-center">
                <div className="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 sm:py-12 lg:px-8">
                    {/* Title Section */}
                    <header>
                        <h2 className="text-xl font-bold text-gray-900 sm:text-3xl">
                            Produse eco din plastic reciclat
                        </h2>

                        <p className="max-w-md mt-4 text-gray-500">
                            Transformăm plasticul în soluții eco-friendly: Descoperă gama noastră de produse reciclate!
                        </p>
                    </header>

                    {/* Top CTA Section */}
                    <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                        <img class="w-full " src={Image7} alt="pagina produse" />

                        <div class="mt-4 md:mt-0">
                            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
                                De la deșeuri la resurse prețioase
                            </h2>
                            <p class="mb-6 font-light text-gray-800 md:text-lg">
                                În misiunea noastră de a reduce impactul negativ asupra planetei, ne concentrăm pe
                                inovație și design, aducând în același timp o contribuție semnificativă la protecția
                                mediului înconjurător. Alegând produsele noastre, te alături unui efort colectiv de a
                                crea un spațiu mai ecologic și mai prosper pentru generațiile viitoare.
                            </p>
                            <button
                                onClick={() => {
                                    navigate("/despre-noi");
                                }}
                                class="inline-flex items-center text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900"
                            >
                                Află mai multe
                                <svg
                                    class="ml-2 -mr-1 w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                    </div>

                    {/* <Spacers /> */}
                    {/* Products Section */}
                    <ul className="grid gap-4 mt-8 sm:grid-cols-2 lg:grid-cols-3">
                        <li>
                            <a href="#" className="block overflow-hidden group">
                                <div className="flex gap-4 items-center flex-col p-2">
                                    <CustomImage img={Image1} />
                                    <div className="px-4 text-center">
                                        <p>
                                            <b>Bidon de plastic</b>
                                            <br /> cu capacitate de la 1L până la 20L
                                        </p>
                                    </div>
                                </div>

                                <div className="relative p-4 m-2 bg-white text-center">
                                    <h3 className="text-xs text-gray-700 group-hover:underline group-hover:underline-offset-4">
                                        Model 1
                                    </h3>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#" className="block overflow-hidden group">
                                <div className="flex gap-4 items-center flex-col p-2">
                                    <CustomImage img={Image2} />
                                    <div className="px-4 text-center">
                                        <p>
                                            <b>Bidon de plastic</b>
                                            <br /> cu capacitate de la 1L până la 20L
                                        </p>
                                    </div>
                                </div>

                                <div className="relative p-4 m-2 bg-white text-center">
                                    <h3 className="text-xs text-gray-700 group-hover:underline group-hover:underline-offset-4">
                                        Model 2
                                    </h3>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#" className="block overflow-hidden group">
                                <div className="flex gap-4 items-center flex-col p-2">
                                    <CustomImage img={Image3} />
                                    <div className="px-4 text-center">
                                        <p>
                                            <b>Sac menajer cu șnur</b>
                                            <br /> cu capacitate de la 30L până la 1200L
                                        </p>
                                    </div>
                                </div>

                                <div className="relative p-4 m-2 bg-white text-center">
                                    <h3 className="text-xs text-gray-700 group-hover:underline group-hover:underline-offset-4">
                                        Model 1
                                    </h3>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <ul className="grid gap-4 mt-8 sm:grid-cols-2 lg:grid-cols-3">
                        <li>
                            <a href="#" className="block overflow-hidden group">
                                <div className="flex gap-4 items-center flex-col p-2">
                                    <CustomImage img={Image4} />
                                    <div className="px-4 text-center">
                                        <p>
                                            <b>Sac menajer cu șnur</b>
                                            <br /> cu capacitate de la 30L până la 1200L
                                        </p>
                                    </div>
                                </div>

                                <div className="relative p-4 m-2 bg-white text-center">
                                    <h3 className="text-xs text-gray-700 group-hover:underline group-hover:underline-offset-4">
                                        Model 2
                                    </h3>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#" className="block overflow-hidden group">
                                <div className="flex gap-4 items-center flex-col p-2">
                                    <CustomImage img={Image5} />
                                    <div className="px-4 text-center">
                                        <p>
                                            <b>Sac menajer clasic</b>
                                            <br /> cu capacitate de la 30L până la 1200L
                                        </p>
                                    </div>
                                </div>

                                <div className="relative p-4 m-2 bg-white text-center">
                                    <h3 className="text-xs text-gray-700 group-hover:underline group-hover:underline-offset-4">
                                        Standard
                                    </h3>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#" className="block overflow-hidden group">
                                <div className="flex gap-4 items-center flex-col p-2">
                                    <CustomImage img={Image6} />
                                    <div className="px-4 text-center">
                                        <p>
                                            <b>Sac gen sacosa</b>
                                            <br />
                                            Pratici, versatili și eco-friendly
                                        </p>
                                    </div>
                                </div>

                                <div className="relative p-4 m-2 bg-white text-center">
                                    <h3 className="text-xs text-gray-700 group-hover:underline group-hover:underline-offset-4">
                                        Standard
                                    </h3>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    );
};

export default ProdusePage;

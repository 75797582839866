import React from "react";
// import img1 from "../../assets/images/Anunt1.jpg";
import img1 from "../../assets/images/AnuntImage1.JPG";
import img2 from "../../assets/images/headerAnunt.png";
import { Link } from "react-router-dom";

const AnunturiArchive = () => {
   const customDetails = [
      {
         id: 1,
         img: img1,
         title: "ANUNT DE PARTICIPARE LA PROCEDURA COMPETITIVA",
      },
      {
         id: 2,
         img: img2,
         title: "Comunicat Martie 2022",
      },
      {
         id: 3,
         img: img2,
         title: "Comunicat Aprilie 2022",
      },
      {
         id: 4,
         img: img2,
         title: "Comunicat Mai 2022",
      },
      {
         id: 5,
         img: img2,
         title: "Comunicat Iunie 2022",
      },
      {
         id: 6,
         img: img2,
         title: "Comunicat Iulie 2022",
      },
      {
         id: 7,
         img: img2,
         title: "Comunicat August 2022",
      },
      {
         id: 8,
         img: img2,
         title: "Comunicat Septembrie 2022",
      },
      {
         id: 9,
         img: img2,
         title: "Comunicat Noiembrie 2022",
      },
      {
         id: 10,
         img: img2,
         title: "Comunicat Decembrie 2022",
      },
      {
         id: 11,
         img: img2,
         title: "Comunicat Ianuarie 2023",
      },
      {
         id: 12,
         img: img2,
         title: "Comunicat Februarie 2023",
      },
      {
         id: 13,
         img: img2,
         title: "Comunicat Martie 2023",
      },
      {
         id: 14,
         img: img2,
         title: "Comunicat Aprilie 2023",
      },
      {
         id: 15,
         img: img2,
         title: "Comunicat Mai 2023",
      },
      {
         id: 16,
         img: img2,
         title: "Comunicat Iunie 2023",
      },
      {
         id: 17,
         img: img2,
         title: "Comunicat Iulie 2023",
      },
      {
         id: 18,
         img: img2,
         title: "Comunicat August 2023",
      },
      {
         id: 19,
         img: img2,
         title: "Comunicat Septembrie 2023",
      },
      {
         id: 20,
         img: img2,
         title: "Comunicat Octombrie 2023",
      },
      {
         id: 21,
         img: img2,
         title: "Comunicat Noiembrie 2023",
      },
      {
         id: 22,
         img: img2,
         title: "Comunicat Decembrie 2023",
      },
      {
         id: 23,
         img: img2,
         title: "Comunicat Finalizare Proiect ROLAV",
      },
   ];

   return (
      <>
         <div className="py-12 w-100">
            <div className="xl:container m-auto px-6 text-gray-600 md:px-12 xl:px-6 ">
               <div className="mb-12 space-y-2 text-center">
                  <h2 className="text-3xl font-bold text-gray-800 md:text-4xl ">
                     Anunturi
                  </h2>
                  <p className="lg:mx-auto lg:w-6/12 text-gray-600 ">
                     Cu tehnologie avansată și o abordare inovatoare,
                     transformăm deșeurile de plastic în resurse valoroase,
                     contribuind la un viitor mai verde.
                  </p>
               </div>
               {customDetails.map((component) => (
                  <div className="flex justify-between pt-1">
                     <div style={{ width: "100%" }}>
                        <div className="flex  group  rounded-3xl bg-white border border-gray-100 bg-opacity-50 shadow-2xl shadow-gray-600/10 ">
                           <div className="relative overflow-hidden rounded-xl">
                              <img
                                 src={img1}
                                 alt="art cover"
                                 loading="lazy"
                                 width="1000"
                                 height="667"
                                 className="h-64 w-full object-cover transition duration-500 group-hover:scale-105"
                              />
                           </div>
                           <div
                              className="mt-6 relative"
                              style={{
                                 width: "50%",
                                 marginLeft: "10px",
                                 display: "flex",
                                 flexDirection: "column",
                                 justifyContent: "space-between",
                              }}
                           >
                              <h3 className="text-2xl font-semibold text-gray-800">
                                 {component.title}
                              </h3>

                              <div
                                 style={{
                                    marginTop: "auto",
                                    fontSize: 20,
                                 }}
                              >
                                 <Link to={`/anunturi/${component.id}`}>
                                    <span
                                       className="text-primary"
                                       style={{ marginBottom: 50 }}
                                    >
                                       Citește mai mult
                                    </span>
                                 </Link>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               ))}
            </div>
         </div>
      </>
   );
};

export default AnunturiArchive;

import React from "react";
import img1 from "../../../assets/images/headerAnunt.png";
import img2 from "../../../assets/images/footerAnunt.png";
import img3 from "../../../assets/images/Anunt1.jpg";
const PrimulAnunt = () => {
  return (
    <>
      {" "}
      <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white ">
        <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
          <article className="mx-auto w-full max-w-3xl format format-sm sm:format-base lg:format-lg format-blue ">
            <figure>
              <img
                src={img1}
                alt="art cover"
                loading="lazy"
                width="1000"
                height="667"
                className="h-[6rem] w-full object-fit transition duration-500 group-hover:scale-105 mt-5 mb-5"
              />
              {/* <figcaption>Digital art by Anonymous</figcaption> */}
            </figure>
            <header className="mb-4 lg:mb-6 not-format">
              <h1 className="mb-4 text-lg font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-xl ">
                COMUNICAT DE PRESA – 2023
              </h1>
            </header>
            <p className="lead mb-2">
              Titlul proiectului: „Achiziție echipamente producție ambalaje de
              plastic ” <br />
              Numele beneficiarului: ROLAV CONSULTING SRL
              <br />
              Obiectivul general al proiectului este: Cresterea competitivitatii
              economice a Rolav Consulting S.R.L. prin respectarea principiilor
              de dezvoltare durabila
              <br />
              Obiectivul specific al proiectului este: Intrarea pe piata
              producatorilor de ambalaje din plastic si consolidarea pozitiei pe
              aceasta piata.
              <br />
              Valoarea totala a proiectului: 3.634.851,43 lei
              <br />
              Valoarea finantarii nerambursabile: 2.582.765,65 lei.
              <br />
              Data de incepere a proiectului: 01/01/2020
              <br />
              Data finalizarii proiectului: 31/12/2023
              <br />
              Cod SMIS proiect: 138785
              <br />
            </p>
            <p>
              Rolav Consulting SRL, in calitate de beneficiar al proiectului, va
              invita sa participati la procedura competitiva pentru atribuirea
              contractului de furnizare echipamente productie ambalaje. Pentru
              obtinerea documentatiei de atribuire a ofertelor ne puteti
              contacta la nr 0731.146.536, sau email lavalenti2008@yahoo.com
            </p>

            <h2 className="mt-2 mb-2">
              {" "}
              Ofertantul castigator va avea obligatia respectarii urmatoarelor
              termene:
            </h2>
            <p className="mb-2">
              Livrarea echipamentelor se va realiza la adresa strada Inginer
              Coarna Teodor, nr 6A, Municipiul Calarasi,judet Calarasi, in
              termen de maxim 125 zile calendaristice de la data semnarii
              contractului. Durata contractului este de maxim 125 zile
              calendaristice, fara posibilitatea ajustarii pretului. Valoarea
              estimata a achizitiei: 2.715.000 RON lei (fara TVA)
            </p>
            <p>
              Va rugam a avea amabilitatea de a ne furniza oferta dvs pana la
              data limita de 03.08.2023 ora 16.00. Depunerea ofertelor in format
              fizic se poate face pana la data si ora limita, la adresa Str.
              Ciocarliei nr. 19 bis, biroul nr 1, judetul Calarasi.
            </p>
            <div className="border border-black p-2 mt-4">
              <p className="text-center">
                <b className="text-center">
                  Rolav Consulting SRL
                  <br /> Persoana de contact: Laurentiu Vasile Gidea,
                  administrator
                  <br /> Tel: 0731 146536 / Email: lavalenti2008@yahoo.com
                </b>
              </p>
            </div>
            <figure>
              <img
                src={img2}
                alt="art cover"
                loading="lazy"
                width="1000"
                height="667"
                className="h-[9rem] w-full object-fit transition duration-500 group-hover:scale-105 mt-5 mb-5"
              />
              {/* <figcaption>Digital art by Anonymous</figcaption> */}
            </figure>
          </article>
        </div>
      </main>
      <aside
        aria-label="Related articles"
        className="py-8 lg:py-24 bg-gray-50 "
      >
        <div className="px-4 mx-auto max-w-screen-xl">
          <h2 className="mb-8 text-2xl font-bold text-gray-900 ">
            Alte anunțuri
          </h2>
          <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
            <article className="max-w-xs">
              <a href="/anunturi/1">
                <img
                  src={img3}
                  alt="Image 1"
                  width="1000"
                  height="667"
                  className="h-[12rem] w-full object-cover transition duration-500 group-hover:scale-105 mt-5 mb-5 rounded-lg "
                />
              </a>
              <h2 className="mb-2 text-xl font-bold leading-tight text-gray-900 ">
                <a href="#">ANUNT DE PARTICIPARE LA PROCEDURA COMPETITIVA</a>
              </h2>
              <p className="mb-4 font-light text-gray-500 ">
                Rolav Consulting SRL, in calitate de beneficiar al proiectului,
                va invita sa participati la procedura competitiva pentru..
              </p>
              <a
                href="#"
                className="inline-flex items-center font-medium underline underline-offset-4 text-green-500  hover:no-underline"
              >
                Citește în 2 minute
              </a>
            </article>
          </div>
        </div>
      </aside>
    </>
  );
};

export default PrimulAnunt;

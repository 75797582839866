import React from "react";
import { useNavigate } from "react-router-dom";
import img from "../../assets/images/home_img.png";
import img2 from "../../assets/images/despre-noi.png";
import img3 from "../../assets/images/home3.jpg";
import Spacers from "../../custom/Spacers";

import img_anunt_1 from "../../assets/images/headerAnunt.png";
import img_anunt_2 from "../../assets/images/footerHomePageV2.png";
// import img_anunt_3 from "../../../assets/images/Anunt1.jpg";

const HomePage = () => {
    const navigate = useNavigate();

    return (
        <>
            <section className="bg-white ">
                <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                    <div className="place-self-center lg:col-span-9">
                        <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl ">
                            Descoperă o alternativă eco-friendly pentru nevoile tale
                        </h1>
                        <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl ">
                            Aducem în fața ta o gamă variată de produse plastice reciclate, contribuind la protejarea
                            mediului și promovând un stil de viață sustenabil. Descoperă soluțiile noastre eco-friendly
                            pentru nevoile tale, fără a compromite calitatea și funcționalitatea.
                        </p>
                        <a
                            href="#"
                            className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-primary-300 "
                        >
                            Mai multe detalii
                            <svg
                                className="w-5 h-5 ml-2 -mr-1"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </a>
                        <a
                            href="#"
                            onClick={() => navigate("contact")}
                            className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 "
                        >
                            Contactează-ne
                        </a>
                    </div>
                    <div className="hidden lg:mt-0 lg:col-span-3 lg:flex">
                        <img src={img} alt="mockup" />
                    </div>
                </div>
            </section>
            <Spacers />
            <section className="bg-white ">
                <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
                    <dl className="grid max-w-screen-md gap-8 mx-auto text-gray-900 sm:grid-cols-3 ">
                        <div className="flex flex-col items-center justify-center">
                            <dt className="mb-2 text-3xl md:text-4xl font-extrabold">100K+</dt>
                            <dd className="font-light text-gray-500 ">Produse reciclate</dd>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <dt className="mb-2 text-3xl md:text-4xl font-extrabold">80K+</dt>
                            <dd className="font-light text-gray-500 ">Produse fabricate</dd>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <dt className="mb-2 text-3xl md:text-4xl font-extrabold">5K+</dt>
                            <dd className="font-light text-gray-500 ">Cumpărători mulțumiți</dd>
                        </div>
                    </dl>
                </div>
            </section>
            <Spacers />
            <section className="bg-white ">
                <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                    <div className="font-light text-gray-500 sm:text-lg ">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
                            Întră într-un viitor sustenabil cu produsele noastre plastice reciclate
                        </h2>
                        <p className="mb-4">
                            Bine ați venit în lumea noastră de produse plastice reciclate! Pășește într-un viitor
                            sustenabil, în care responsabilitatea față de mediul înconjurător se îmbină cu inovația și
                            calitatea. Descoperă gama noastră diversificată de produse plastice reciclate, realizate cu
                            pasiune și expertiză. Împreună, putem face o diferență pozitivă pentru planeta noastră.
                            Alege produsele noastre eco-friendly și fii parte a mișcării de reducere a deșeurilor și
                            promovare a economiei circulare. Începe astăzi și schimbă lumea cu fiecare achiziție!
                        </p>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-8">
                        <img className="w-full rounded-lg" src={img3} alt="office content 1" />
                        <img className="mt-4 w-full lg:mt-10 rounded-lg" src={img2} alt="office content 2" />
                    </div>
                </div>
            </section>
            <Spacers />
            <section className="bg-white ">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="max-w-screen-lg text-gray-500 sm:text-lg ">
                        <h2 className="mb-4 text-4xl tracking-tight font-bold text-gray-900 ">
                            Angajamentul nostru față de <span className="font-extrabold">sustenabilitate</span>
                        </h2>
                        <p className="mb-4 font-light">
                            La firma noastră, suntem dedicați protejării mediului și construirii unui viitor mai bun
                            pentru generațiile viitoare. Ne-am asumat responsabilitatea de a reduce impactul negativ al
                            plasticului asupra mediului prin producția și comercializarea de produse plastice reciclate.
                            Colaborăm cu parteneri de încredere și adoptăm cele mai riguroase standarde de calitate și
                            durabilitate pentru fiecare produs.
                        </p>
                        <p className="mb-4 font-medium">
                            De la procesele noastre de fabricație eco-friendly la ambalajele noastre reciclabile, luăm
                            în considerare fiecare aspect al ciclului de viață al produselor noastre. Încurajăm consumul
                            responsabil și promovăm economia circulară prin transformarea deșeurilor de plastic în noi
                            obiecte utile și de înaltă calitate.
                        </p>
                        <a
                            href="#"
                            className="inline-flex items-center font-medium text-green-500 hover:text-green-600 "
                        >
                            Află mai multe
                            <svg
                                className="ml-1 w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </section>

            {/* //! Anunt general */}
            {/* <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-gray-200 ">
                <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
                    <article className="mx-auto w-full max-w-5xl px-2 md:px-24 format format-sm sm:format-base lg:format-lg format-blue ">
                        <figure>
                            <img
                                src={img_anunt_1}
                                alt="art cover"
                                loading="lazy"
                                width="1000"
                                height="667"
                                className="h-[6rem] w-full object-fit transition duration-500 group-hover:scale-105 mt-5 mb-5"
                            />
                        </figure>
                        <header className="mb-4 lg:mb-6 not-format">
                            <div className="flex flex-col  justify-center items-center">
                                <h1 className="text-3xl font-extrabold leading-tight text-blue-900">Anunt general</h1>
                                <h2 className="text-3xl font-extrabold leading-tight text-blue-900 mt-2">
                                    Rolav Consulting SRL
                                </h2>
                            </div>
                        </header>
                        <p className="lead mb-2">
                            <p>Titlul proiectului: „Achiziție echipamente producție ambalaje de plastic”</p>
                            <br />
                            <p>Numele beneficiarului: ROLAV CONSULTING SRL</p>
                            <br />
                            <p>Obiectivele proiectului:</p>
                            <br />
                            <p>
                                Obiectivul general al proiectului este: Cresterea competitivitatii economice a Rolav
                                Consulting S.R.L. prin respectarea principiilor de dezvoltare durabila
                            </p>
                            <br />
                            <p>
                                Obiectivul specific al proiectului este: Intrarea pe piata producatorilor de ambalaje
                                din plastic si consolidarea pozitiei pe aceasta piata.
                            </p>
                            <br />
                            <p>Valoarea totala a proiectului: 3.634.851,43 lei</p>
                            <br />
                            <p>
                                Valoarea contributiei nerambursabile: 2.582.765,65 lei din care contributia FEDR
                                2.195.350,80 lei.
                            </p>
                            <br />
                            <p>Data de incepere a proiectului: 01/01/2020</p>
                            <br />
                            <p>Data finalizarii proiectului: 31/12/2023</p>
                            <br />
                            <p>Cod SMIS proiect: 138785</p>
                            <br />
                            <p>Contract de finantare nr. 7473/24.12.2021</p>
                            <br />
                            <p>
                                Finantat prin Programul Operational Regional 2014-2020, Axa Prioritara 2, “Imbunatatirea
                                competitivitatii intreprinderilor mici si mijlocii”, Prioritatea de investiții 2.2 –
                                Sprijinirea creării și extinderea capacităților avansate de producție și dezvoltarea
                                serviciilor
                            </p>
                            <br />
                            <p>
                                Autoritatea de Management POR: Ministerul Investitiilor si Proiectelor Europene Organism
                                Intermediar: Agentia pentru Dezvoltare Regionala Sud-Muntenia Proiectul sustine
                                principiile de nediscriminare, reducerea excluziunii sociale, egalitatea de sanse si de
                                gen precum si accesul la calificare si perfectionare profesionala.
                            </p>
                            <br />
                        </p>

                        <br />
                        <div className="border border-black p-2 mt-4">
                            <p className="text-center">
                                <b className="text-center">
                                    Rolav Consulting SRL
                                    <br /> Persoana de contact: Laurentiu Vasile Gidea, administrator
                                    <br /> Tel: 0731 146536 / Email: lavalenti2008@yahoo.com
                                </b>
                            </p>
                        </div>
                        <figure>
                            <img
                                src={img_anunt_2}
                                alt="art cover"
                                loading="lazy"
                                width="1000"
                                height="657"
                                className="md:h-[5rem] h-[3.5rem] w-full object-fit transition duration-500 group-hover:scale-105 mt-5 mb-5"
                            />
                        </figure>

                        <div
                            className="flex flex-col md:flex-row  justify-center items-center -mt-3"
                            style={{ color: "#666eb6" }}
                        >
                            <a
                                href="https://regio.adrmuntenia.ro/"
                                target="_blank"
                                className="text-sm font-extrabold leading-tight"
                            >
                                <b>regio.adrmuntenia.ro</b>
                            </a>
                            <p className="mx-2">
                                <b> | </b>
                            </p>
                            <a
                                href="https://www.facebook.com/adrsudmuntenia"
                                target="_blank"
                                className="text-sm font-extrabold leading-tight"
                            >
                                <b>facebook.com/adrsudmuntenia</b>
                            </a>
                            <p className="mx-2">
                                <b> | </b>
                            </p>
                            <a
                                href="https://www.inforegio.ro/ro/"
                                target="_blank"
                                className="text-sm font-extrabold leading-tight"
                            >
                                <b>www.inforegio.ro</b>
                            </a>
                            <p className="mx-2">
                                <b> | </b>
                            </p>
                            <a
                                href="https://www.facebook.com/inforegio.ro"
                                target="_blank"
                                className="text-sm font-extrabold leading-tight"
                            >
                                <b>facebook.com/inforegio.ro</b>
                            </a>
                        </div>
                        <div className="flex flex-row justify-center items-center text-black mt-5">
                            <p className="text-xs font-light leading-tight text-center">
                                Conținutul acestui material nu reprezintă în mod obligatoriu poziția oficială a Uniunii
                                Europene sau a Guvernului României
                            </p>
                        </div>
                    </article>
                </div>
            </main> */}
            {/* //! END Anunt general */}
        </>
    );
};

export default HomePage;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// paages
import {
  HomePage,
  DespreNoiPage,
  InformatiiPage,
  ProdusePage,
  RecicleazPage,
  LegislatiePage,
  ContactPage,
  AnunturiPage,
  AnuntHandlePage,
  ProiecteEuropenePage,
  ErrorPage
} from "./pages";
// layout
import Layout from "./layout";
import ScrollToTop from "./layout/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/despre-noi" element={<DespreNoiPage />} />
          <Route path="/proiecte-europene" element={<ProiecteEuropenePage />} />
          <Route path="/informatii" element={<InformatiiPage />} />
          <Route path="/produse" element={<ProdusePage />} />
          <Route path="/recicleaza-cu-noi" element={<RecicleazPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/legislatie" element={<LegislatiePage />} />
          <Route path="/anunturi" element={<AnunturiPage />} />
          <Route path="/anunturi/:id" element={<AnuntHandlePage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Layout>
      <ScrollToTop />
    </BrowserRouter>
  );
}

export default App;

// Logo
import Logo from "../assets/logo.png";
import {Link} from "react-router-dom"
const HeaderLogo = ({ width }) => {
  return (
    <div className=" ml-2 flex  rounded-md p-2 w-50 item-center">
      <Link to="/" >
      <img
        style={{
          width: width,
        }}
        src={Logo}
        alt="Rolav"
        />
        </Link>
    </div>
  );
};

export default HeaderLogo;
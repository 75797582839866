import React from "react";

const InformatiiPage = () => {
    return (
        <section>
            <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <h2 className="mb-8 text-4xl tracking-tight font-extrabold  text-black">Intrebari frecvente</h2>
                <div className="grid pt-8 text-left border-t border-green-600 md:gap-16  md:grid-cols-2">
                    <div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-2xl font-semibold  text-green-600">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5  text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                Cum funcționează procesul vostru de reciclare a plasticului?
                            </h3>
                            <p className=" text-gray-500">
                                În procesul nostru de reciclare, colectăm plasticul utilizat, îl sortăm, curățăm și
                                transformăm în granule. Apoi, granulele de plastic reciclat sunt folosite pentru a
                                fabrica diverse produse eco-friendly.
                            </p>
                        </div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-2xl font-semibold  text-green-600">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5  text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                Ce tipuri de produse realizate din plastic reciclat oferiți?
                            </h3>
                            <p className=" text-gray-500">
                                Oferim o gamă variată de produse, inclusiv saci gen sacoșă, bidoane de plastic ca
                                produse principale și granule LDPE si HDPE ca produse secundare .
                            </p>
                        </div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-2xl font-semibold  text-green-600">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5  text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                Cum pot să contribui ca individ la eforturile voastre de reciclare?
                            </h3>
                            <p className=" text-gray-500">
                                Puteți contribui la eforturile noastre de reciclare prin colectarea și predarea
                                deșeurilor de plastic la centrele de colectare desemnate. Astfel, ajutați la reducerea
                                cantității de plastic care ajunge în mediu.
                            </p>
                        </div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-2xl font-semibold  text-green-600">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5  text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                Cum vă asigurați că produsele voastre sunt de calitate și ecologice?
                            </h3>
                            <p className=" text-gray-500">
                                Calitatea și durabilitatea sunt aspecte fundamentale pentru noi. Verificăm cu atenție
                                toate materialele folosite și procesele de producție pentru a ne asigura că produsele
                                noastre sunt atât de înaltă calitate, cât și prietenoase cu mediul.
                            </p>
                        </div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-2xl font-semibold  text-green-600">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5  text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                Cum se compară prețurile produselor voastre cu cele din plastic obișnuit?
                            </h3>
                            <p className=" text-gray-500">
                                Prețurile produselor noastre sunt competitive și reflectă angajamentul nostru față de
                                calitate și sustenabilitate. Considerăm că prețurile sunt juste având în vedere
                                beneficiile aduse mediului înconjurător și calitatea produselor noastre.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-2xl font-semibold  text-green-600">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5  text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                Care sunt avantajele produselor din plastic reciclat comparativ cu cele din plastic nou?
                            </h3>
                            <p className=" text-gray-500">
                                Produsele din plastic reciclat au un impact mai mic asupra mediului, reducând consumul
                                de resurse naturale și cantitatea de deșeuri. De asemenea, contribuie la scăderea
                                emisiilor de carbon și la protejarea ecosistemelor fragile.
                            </p>
                        </div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-2xl font-semibold  text-green-600">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5  text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                Cum puteți garanta că deșeurile de plastic colectate sunt reciclate corespunzător?
                            </h3>
                            <p className=" text-gray-500">
                                Colaborăm cu parteneri de încredere și facilități de reciclare autorizate, asigurându-ne
                                că procesul de reciclare se desfășoară conform celor mai înalte standarde și că
                                deșeurile de plastic sunt valorificate în mod responsabil.
                            </p>
                        </div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-2xl font-semibold  text-green-600">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5  text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                Unde pot găsi produsele voastre eco-friendly realizate din plastic reciclat?
                            </h3>
                            <p className=" text-gray-500">
                                Produsele noastre pot fi găsite în magazinele noastre fizice partenere și pe site-ul
                                nostru oficial de vânzări online.
                            </p>
                        </div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-2xl font-semibold  text-green-600">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5  text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                Ce măsuri luați pentru a minimiza impactul asupra mediului în timpul procesului de
                                producție?
                            </h3>
                            <p className=" text-gray-500">
                                Adoptăm tehnologii și metode de producție eficiente energetic, reducând emisiile de
                                carbon și consumul de apă. De asemenea, ne străduim să minimizăm cantitatea de deșeuri
                                generată în timpul producției și să utilizăm ambalaje eco-friendly.
                            </p>
                        </div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-2xl font-semibold  text-green-600">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5  text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                Care sunt inițiativele voastre de responsabilitate socială legate de reciclare?
                            </h3>
                            <p className=" text-gray-500">
                                Suntem implicați în proiecte și campanii de conștientizare privind importanța
                                reciclării, colaborăm cu organizații non-guvernamentale și ne implicăm în programe de
                                educație ecologică în comunitate.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default InformatiiPage;

import React from "react";
import Spacers from "../../custom/Spacers";
import Image1 from "../../assets/images/plastic-reciclat2.png";
import Image2 from "../../assets/images/plastic-reciclat.jpg";
import Image3 from "../../assets/images/despre-noi.png";
import Image4 from "../../assets/images/bidoane 10-20.png";

const DespreNoiPage = () => {
    return (
        <div>
            <section className="antialiased">
                <div className="max-w-screen-xl px-4 py-8 mx-auto lg:px-6 sm:py-16 lg:py-24">
                    <div className="max-w-2xl mx-auto text-center">
                        <h2 className="text-3xl font-extrabold leading-tight tracking-tight ">
                            Despre Noi: Transformăm Reciclarea în Inovație Eco-Friendly
                        </h2>
                        <p className="mt-4 text-base font-normal text-black sm:text-xl ">
                            Descoperă povestea noastră dedicată sustenabilității și pasiunii pentru un viitor mai verde
                        </p>
                    </div>

                    <div className="grid grid-cols-1 mt-12 text-center sm:mt-16 gap-x-20 gap-y-12 sm:grid-cols-2 lg:grid-cols-3">
                        <div className="space-y-4">
                            <h3 className="text-2xl mb-10 font-bold leading-tight text-black "> Valorile Noastre </h3>
                            <ul class="space-y-4 text-center text-lg font-normal text-black ">
                                <li class="flex items-center space-x-3">
                                    <svg
                                        class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 16 12"
                                    >
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M1 5.917 5.724 10.5 15 1.5"
                                        />
                                    </svg>
                                    <span>Sustenabilitate </span>
                                </li>
                                <li class="flex items-center space-x-3">
                                    <svg
                                        class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 16 12"
                                    >
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M1 5.917 5.724 10.5 15 1.5"
                                        />
                                    </svg>
                                    <span>Inovație</span>
                                </li>
                                <li class="flex items-center space-x-3">
                                    <svg
                                        class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 16 12"
                                    >
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M1 5.917 5.724 10.5 15 1.5"
                                        />
                                    </svg>
                                    <span>Impact Pozitiv</span>
                                </li>
                                <li class="flex items-center space-x-3">
                                    <svg
                                        class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 16 12"
                                    >
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M1 5.917 5.724 10.5 15 1.5"
                                        />
                                    </svg>
                                    <span>Angajament</span>
                                </li>
                            </ul>
                        </div>
                        <div className="space-y-4">
                            <h3 className="text-2xl mb-10 font-bold leading-tight text-black ">Produse Eco-Friendly</h3>
                            <ul class="space-y-4 text-center text-lg font-normal text-black ">
                                <li class="flex items-center space-x-3">
                                    <svg
                                        class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 16 12"
                                    >
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M1 5.917 5.724 10.5 15 1.5"
                                        />
                                    </svg>
                                    <span>Saci eleganți si pracici</span>
                                </li>
                                <li class="flex items-center space-x-3">
                                    <svg
                                        class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 16 12"
                                    >
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M1 5.917 5.724 10.5 15 1.5"
                                        />
                                    </svg>
                                    <span>Bidoane</span>
                                </li>
                                <li class="flex items-center space-x-3">
                                    <svg
                                        class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 16 12"
                                    >
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M1 5.917 5.724 10.5 15 1.5"
                                        />
                                    </svg>
                                    <span>LDPE și HDPE</span>
                                </li>
                            </ul>
                        </div>
                        <div className="space-y-4">
                            <h3 className="text-2xl mb-10 font-bold leading-tight text-black ">
                                {" "}
                                Responsabilitate Socială
                            </h3>
                            <ul class="space-y-4 text-center text-lg font-normal text-black ">
                                <li class="flex items-center space-x-3">
                                    <svg
                                        class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 16 12"
                                    >
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M1 5.917 5.724 10.5 15 1.5"
                                        />
                                    </svg>
                                    <span>Campanii de Conștientizare</span>
                                </li>
                                <li class="flex items-center space-x-3">
                                    <svg
                                        class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 16 12"
                                    >
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M1 5.917 5.724 10.5 15 1.5"
                                        />
                                    </svg>
                                    <span>Educație Ecologică</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* 1 */}

            <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
                <div className="absolute inset-0 -z-10 overflow-hidden">
                    <svg
                        className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                                width={200}
                                height={200}
                                x="50%"
                                y={-1}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M100 200V.5M.5 .5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                            <path
                                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect
                            width="100%"
                            height="100%"
                            strokeWidth={0}
                            fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
                        />
                    </svg>
                </div>
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                    <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                        <div className="lg:pr-4">
                            <div className="lg:max-w-lg">
                                <p className="text-base font-semibold leading-7 text-indigo-600">Eco-friendly</p>
                                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                    Transformăm Reciclarea în Inovație Eco-Friendly
                                </h1>
                                <p className="mt-6 text-xl leading-8 text-gray-700">
                                    La ROLAV, ne-am asumat o misiune ambițioasă - transformarea reciclării în inovație
                                    eco-friendly pentru un viitor mai bun. Suntem o echipă unită de oameni dedicați,
                                    care împărtășesc pasiunea pentru protecția mediului și aducem în prim plan
                                    responsabilitatea socială.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="-ml-[6rem] lg:ml-12 -mt-20 p-12 lg:sticky lg:top-[10rem] lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                        <img
                            className="w-[48rem] h-[25rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                            src={Image1}
                            alt=""
                        />
                    </div>
                    <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                        <div className="lg:pr-4">
                            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                                <p>
                                    Fiecare produs pe care îl fabricăm este rezultatul unui angajament puternic față de
                                    sustenabilitate. Începând cu colectarea responsabilă a deșeurilor de plastic,
                                    sortarea cu grijă și reciclarea inteligentă, fiecare etapă este tratată cu respect
                                    și responsabilitate față de mediul înconjurător.
                                </p>

                                <p className="mt-8">
                                    În fiecare produs ROLAV se ascunde angajamentul nostru către un viitor durabil, iar
                                    fiecare achiziție devine un pas important spre un mediu mai bun și o viață mai
                                    sănătoasă. Împreună putem crea o lume mai bună pentru noi și pentru generațiile
                                    viitoare.
                                </p>
                                <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                                    Responsabilitate Socială și Impact Pozitiv
                                </h2>
                                <p className="mt-6">
                                    Suntem conștienți că putem face o schimbare pozitivă doar împreună cu comunitatea
                                    noastră. De aceea, suntem activi în proiecte de responsabilitate socială și campanii
                                    de conștientizare ecologică, promovând educația și acțiunile concrete în protejarea
                                    mediului.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 2 */}
            <section>
                <div
                    id="s2"
                    class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6"
                >
                    <div class="font-light text-black sm:text-lg ">
                        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-green-600 ">
                            De la Deșeuri la Resurse Prețioase
                        </h2>
                        <p class="mb-4">
                            Responsabilitatea noastră față de mediul înconjurător stă la baza fiecărui pas pe care-l
                            facem. Colaborăm cu cei mai buni specialiști și utilizăm tehnologii de vârf pentru a asigura
                            o reciclare eficientă și un proces de valorificare optimă a plasticului.
                        </p>
                        <p>
                            Fiecare achiziție de la ROLAV este o investiție într-un mediu mai curat și mai sănătos. Ne
                            mândrim că contribuim la reducerea deșeurilor de plastic și la prevenirea poluării,
                            asigurându-ne că fiecare produs are o amprentă ecologică minimă.
                        </p>
                    </div>
                    <div class="grid grid-cols-2 gap-4 mt-8">
                        <img class="w-full rounded-lg" src={Image2} alt="office content 1" />
                        <img class="mt-4 w-full lg:mt-10 rounded-lg" src={Image3} alt="office content 2" />
                    </div>
                </div>
            </section>
            <Spacers />
            {/* 3 */}
            <section>
                <div
                    id="s3"
                    class="gap-16 flex items-center py-8 px-4 mx-auto max-w-screen-xl lg:flex-row flex-col-reverse lg:py-16 lg:px-6"
                >
                    <div class="lg:w-3/6 font-light text-black sm:text-lg ">
                        <img class="  rounded-lg" src={Image4} alt="office content 1" />
                    </div>
                    <div class="lg:w-3/6 flex flex-col">
                        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-green-600 ">
                            Împreună pentru o Lume Mai Verde
                        </h2>
                        <p class="mb-4">
                            Descoperă produsele noastre eco-friendly și implică-te în inițiativele noastre de
                            responsabilitate socială. Fiecare gest contează, iar împreună putem face o schimbare
                            semnificativă în protejarea mediului înconjurător și în construirea unui viitor sustenabil
                            pentru toți. Alege ROLAV - Pentru un Mediu Mai Bun, Pentru O Viață Mai Bună!
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default DespreNoiPage;

import React from "react";
import Spacers from './../../../custom/Spacers';

const Form = () => {
    return (
        <>
            <div className="pt-6 pb-0 lg:pt-16 lg:pb-5 px-4 mx-auto max-w-screen-md">
                <h2 className="mb-6 text-4xl tracking-tight font-extrabold text-center text-gray-900">
                    Contactează-ne
                </h2>
                <p className="mb-2 lg:mb-0 font-light text-center text-gray-500 sm:text-xl">
                    Contactează-ne pentru a afla mai multe despre produsele noastre reciclate sau pentru a ne trimite
                    feedback-ul tău
                </p>
            </div>
            <Spacers />
            <section className="bg-white grid grid-cols-1 lg:grid-cols-2 gap-16 w-2/3">
                <div className="py-8 lg:py-16 px-4 mx-auto w-full ">
                    <form action="#" className="space-y-8">
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                                placeholder="email@gmail.com"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Subiect
                            </label>
                            <input
                                type="text"
                                id="subject"
                                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 "
                                placeholder="Lasă-ne să știm cum te putem ajuta"
                                required
                            />
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Mesajul tău
                            </label>
                            <textarea
                                id="message"
                                rows="6"
                                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
                                placeholder="Lasă un mesaj..."
                            ></textarea>
                        </div>
                        <button
                            type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-green-500 sm:w-fit hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-primary-300 "
                        >
                            Trimite mesajul
                        </button>
                    </form>
                </div>
                <div className=" mt-10 lg:ml-32 ml:0 lg:mt-0 w-full shrink-0 grow-0 basis-auto flex items-center justify-center">
                    <div className="flex flex-col justify-end flex-wrap">
                        <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                            <div className="flex items-start">
                                <div className="shrink-0">
                                    <div className="inline-block rounded-md bg-green-300 p-4 text-primary">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="2"
                                            stroke="currentColor"
                                            className="h-6 w-6"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-6 grow">
                                    <p className="mb-2 font-bold ">Phone number</p>
                                    <p className="text-neutral-500 ">0731 146 536</p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                            <div className="flex items-start">
                                <div className="shrink-0">
                                    <div className="inline-block rounded-md bg-green-300 p-4 text-primary">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            viewBox="0 0 512 512"
                                        >
                                            <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-6 grow">
                                    <p className="mb-2 font-bold">E-mail</p>
                                    <p className="text-neutral-500">lavalenti2008@yahoo.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                            <div className="align-start flex">
                                <div className="shrink-0">
                                    <div className="inline-block rounded-md bg-green-300 p-4 text-primary">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            viewBox="0 0 576 512"
                                        >
                                            <path d="M302.8 312C334.9 271.9 408 174.6 408 120C408 53.7 354.3 0 288 0S168 53.7 168 120c0 54.6 73.1 151.9 105.2 192c7.7 9.6 22 9.6 29.6 0zM416 503l144.9-58c9.1-3.6 15.1-12.5 15.1-22.3V152c0-17-17.1-28.6-32.9-22.3l-116 46.4c-.5 1.2-1 2.5-1.5 3.7c-2.9 6.8-6.1 13.7-9.6 20.6V503zM15.1 187.3C6 191 0 199.8 0 209.6V480.4c0 17 17.1 28.6 32.9 22.3L160 451.8V200.4c-3.5-6.9-6.7-13.8-9.6-20.6c-5.6-13.2-10.4-27.4-12.8-41.5l-122.6 49zM384 255c-20.5 31.3-42.3 59.6-56.2 77c-20.5 25.6-59.1 25.6-79.6 0c-13.9-17.4-35.7-45.7-56.2-77V449.4l192 54.9V255z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-6 grow">
                                    <p className="mb-2 font-bold">Address</p>
                                    <p className="text-neutral-500">
                                        CALARASI, str. Ing. Coarna Teodor, nr 6A, jud. CALARASI
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                            <div className="align-start flex">
                                <div className="shrink-0">
                                    <div className="inline-block rounded-md bg-green-300 p-4 text-primary">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            viewBox="0 0 512 512"
                                        >
                                            <path d="M224 32H64C46.3 32 32 46.3 32 64v64c0 17.7 14.3 32 32 32H441.4c4.2 0 8.3-1.7 11.3-4.7l48-48c6.2-6.2 6.2-16.4 0-22.6l-48-48c-3-3-7.1-4.7-11.3-4.7H288c0-17.7-14.3-32-32-32s-32 14.3-32 32zM480 256c0-17.7-14.3-32-32-32H288V192H224v32H70.6c-4.2 0-8.3 1.7-11.3 4.7l-48 48c-6.2 6.2-6.2 16.4 0 22.6l48 48c3 3 7.1 4.7 11.3 4.7H448c17.7 0 32-14.3 32-32V256zM288 480V384H224v96c0 17.7 14.3 32 32 32s32-14.3 32-32z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-6 grow">
                                    <p className="mb-2 font-bold ">Orientation</p>
                                   <a href="https://www.google.com/maps/search/CALARASI,+str.+Ing.+Coarna+Teodor,+nr+6A,+jud.+CALARASI/@44.2088965,27.2832148,13z/data=!3m1!4b1?entry=ttu"><button className="inline-flex items-center justify-center px-3 py-2 mr-3 text-base font-medium text-center text-white rounded-lg bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-primary-300 ">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="1em"
                                            viewBox="0 0 320 512"
                                            className="mr-2"
                                        >
                                            <path d="M16 144a144 144 0 1 1 288 0A144 144 0 1 1 16 144zM160 80c8.8 0 16-7.2 16-16s-7.2-16-16-16c-53 0-96 43-96 96c0 8.8 7.2 16 16 16s16-7.2 16-16c0-35.3 28.7-64 64-64zM128 480V317.1c10.4 1.9 21.1 2.9 32 2.9s21.6-1 32-2.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32z" />
                                        </svg>
                                        Google Maps
                                    </button>
                                    </a> 
                          
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Form;
